<template>
    <input :type="type" :value="value" :class="mode" tabindex="0" />
</template>

<script>
export default {
    props: {
        value: { type:String, default:'' },
        type: { type:String, default:'button' },
        mode: { type:String, default:'' }
    }
}
</script>

<style scoped lang="scss">
input {
    border:0px;
    height:40px;
    line-height:40px;
    padding-left:20px;
    padding-right:20px;
    text-align:center;
    box-sizing:border-box;
    min-width:145px;
    box-shadow:0px 0px 9px rgba(0,0,0,0.2), inset 0px 0px 16px rgba(0,0,0,0.16);
    cursor:pointer;
    background-color:var(--button-background-color);
    color:var(--button-text-color);
    font-size:var(--button-font-size);
    line-height:var(--button-line-height);
    margin:0px;
    border-radius:4px;

    &:hover {
        background-color:var(--button-background-color-hover);
    }

    &.light {
        background-color:var(--button-light-background-color);
        &:hover {
            background-color:var(--button-light-background-color-hover);
        }
    }
}
@media only screen and (max-width:768px) {
    input {
        min-width:inherit;
        font-size:var(--button-link-font-size-mini);
    }
}
</style>
